/*****  000 Reset & Imports *****/

@import url("https://use.typekit.net/enr6meh.css");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400,700;0,700;1,400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    translate: 0;
}

html {
  font-size: 24px;
}

/*****  00-1 Design/Brand Guide *****/
/*       Used as the base case of design elements of the site, designed for mobile */

h1, h2, h6 {
  font-family: "Variex", sans-serif; 
}

h3, h4, h5, p {
  font-family: "Quicksand", serif;
  font-weight: 400;
  font-style:regular;
  margin-bottom: -.25em;
}

hr {
  border: thin dotted #F3F3F3; /* ratio is major 2nd smaller from h6 */
  width: 80%;
  margin: 0 auto;
}

ul li {                         /* unordered lists have no bullets */
  list-style-type: none;
  font-family: "Quicksand", sans-serif;
}

.font-1 {                      /* Quickly swap in Quicksand font */
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
}

.font-2 {                     /* Quickly swap in Lora font */
  font-family: 'Lora', serif;
}

h1, h2, h3, h4 {
  font-weight: 700;
}

h5 {
  font-weight: 400;
}

h6 {
  font-weight: 500;
}

/*** selected font weights based on a Cadd9 chord using 4 notes,  ***/

h1 {
  font-size: 2.083rem; /* ratio is perfect fifth from next lowest */
}

h2 {
  font-size: 1.563rem; /* ratio is major 3rd from next lowest */
}

h3 {
  font-size: 1.25rem; /* ratio is major 3rd from next lowest */
}

h4 {
  font-size: 1rem; /* base of "chord" */
}

h5 {
  font-size: 0.75rem; /* ratio is perfect 4th smaller from base */
}

h6 {
  font-size: 0.6rem; /* ratio is major 3rd smaller from h5 */
}

/***** 00-2 Irregular Font Calls *****/

.non-em {
  font-weight: 400;
  font-style: normal;
}

/***** 00-1 Main Elements *****/


/***** 00-2 Hover and Active Links/Buttons *****/

a {
  color: #0d004f;
  text-decoration: none;
  cursor: pointer;
  transition: 1s
}

a:hover{
  color: #4863F2; 
  transition: 1s;
}

/***** 00-3 Colour Swatches *****/
.swatch-1 {
  background-color: #0d004f; /* Vibrant Salmon*/
  color: #b27d08; ; /* White Smoke*/
}

.swatch-1 a {
  color:#F3F3F3;
}

.swatch-1 a:hover {
  color:#F3F3F3;
}

.swatch-2 {
  background-color: #b27d08; /* Electric Blue */
  color: #F3F3F3; /* White Smoke */
}

/***** 00-4 Forms *****/

form{
  font-size: 1rem;
}

input{
  font-family: "Quicksand", serif;
  font-size: 1rem;
  background-color: #F3F3F3;
  color: #04071A;
  border-radius: 5px;
  padding: .5em 15px;
  margin: 0 2rem 0 0;
  border: 0px solid hsla(0,0%,0%,0);
}

textarea{
  font-family: "Quicksand", serif;
  font-size: 1rem;
  background-color: #F3F3F3;
  color: #04071A;
  border-radius: 5px;
  padding: .5em 5%;
  margin: 0 2em 0 0;
  border: 0px solid hsla(0,0%,0%,0);
}

input.btn {
  background-color: #0d004f;
  color: #F3F3F3;
  width: 4rem;
  display: inline-block;
}

input.btn:hover {
  background-color: #b27d08;
  transition: 1s;
}

input.btn:active {
  border: .1rem solid #b27d08;
}

form section {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 0rem 2rem 0rem;
}

form section.submit-btn {
  display: inline-flex;
  width: 15%;
  margin-bottom: 0;
}

.ramen-form-done, .ramen-form-fail {
  display: none;
}

aside.ramen-form {

  margin: 1em 5% 0 5%;

}

/***** 00-05 Buttons *****/

.btn {
  display: inline-block;
  transition: opacity .5s, transform .25s; 
  transform: translate(0px,0px);
  margin-top: .5em;
  border-radius: 1rem;
  padding: 10px 20px;
  margin-left: -20px;
  border: .1em solid #F3F3F3;
  color: #F3F3F3 !important;
  transform: translate3d(5px,-5px,0px);
  box-shadow: -5px 5px#F3F3F3;
  transition: .5s;
}

.btn:hover {
  transform: translate3d(0px,0px,0px);
  transition: all 0.3s ease-in-out;
  border: .1em solid #F3F3F3;
  box-shadow: 0px 0px #F3F3F3;
}

.btn:active {
  color: #F3F3F3 !important;
  border: .1em solid #F3515E;
  transition: all .5s ease-in-out;
}

/*****  0-1 Main Header  *****/

.main-nav {
  max-width: 1790px;
  margin: 0 auto;
}

.main-nav-bar {
  display: inline-flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  width: 100%;
  padding: 1rem 5%;
  align-items: flex-start;
}

.main-nav-bar .header-img {
  justify-self: self-start;
  width: 30%;
  display: inline-flex;
  flex-shrink: 1;
}

.header-img img {
  margin: 0;
  width: 100%;
  flex-shrink: 1;
}

.main-nav-bar nav {
  display: inline-flex;
  justify-items: right;
  min-width: 40%;
  margin-left: auto;
  margin-right: 0px;
}

.main-nav-bar .header-nav {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0px;
  flex-shrink: 3;
}

.main-nav-bar .header-nav li{
  width: 100%;
  text-align: center;
  margin-left: auto;
  display: inline-flex;
  flex-wrap: nowrap;
}

.header-nav .nav-item {
  flex-shrink: 2;
  text-align: center;
  padding: .5rem .5rem;
  cursor: pointer;
  margin-right: 0%;
  margin-left: auto;
}

.header-nav .nav-item.first {
  padding-top: 0rem;
}

.header-img {
  min-width: 50px;
  flex-basis: 200px;
  flex-grow: 2;
  overflow: visible;
}

.header-nav {
  display: inline-flex;
  justify-content: space-between;
  overflow: visible;
  flex-shrink: 6;
}

.header-nav li {
  display: inline-flex;
  flex-shrink: 5;
  /*background-color: rgba(37, 165, 122, 0.3);*/
  overflow: visible;
  margin: 0px 0px;
}

.header-nav a {
  padding-right: 1px;
  padding-left: 1px;
  text-align: center;
}

.header-nav .nav-item.last {
  width: 8.5rem;
  padding-left: -1rem;
  padding-right: -1rem;
}

.nav-item.logo {
  max-width: 150px;
  justify-content: left;
  display: inline-block;
}

.nav-item a:hover {
  color:  #04071A;
  transition: 0.5s;
}


/***** 0-2 Main Body *****/

.box-set{
  max-width: 100%;
  align-content: center;
  margin: 0 auto;
  padding: 2rem 2% 2rem 2%;
  overflow: hidden;
}

/***** 0-3 Main Footer*****/

footer {
  position: relative;
  bottom: 100%;
  padding: 3em 5%;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 3px 3px 0px 0px;
  text-align: left;
}

.foot-location {
  width: 40%;
  min-width: 300px;
  padding: 0 15% 0% 0%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1em;
}

.foot-contact {
  padding: 0 0% 0% 0%;
  width: 40%;
  margin-right: 15%;
  min-width: 300px;
  display: inline-block;
  vertical-align: top;
}

.foot-email {
  word-break: break-word;
}

.copyright {
  text-align: center;
}

.foot-btn {
  display: inline-block;
  margin: 0.5em 0px 0em -10px;
  color: #b27d08 !important;
  line-height: 1.5em 0em;
  border-radius: 1rem;
  box-shadow: 0px 0px #0d004f;
  border: 2px solid #0d004f;
  transform: translate3d(0px,0px,0px);
  padding: 10px 10px;
}

.foot-btn:hover {
  color: #b27d08 !important;
  transform: translate3d(0px,-5px,0px);
  box-shadow: 0px 5px #F3F3F3;
  border-radius: 1rem;
  border: 2px solid #F3F3F3;
  transition: box-shadow 0.5s ease-in-out 1s, transform 0.5s ease-in-out 1s, border 0.3 0.3s;
}

.foot-btn:active {
  transform: translate3d(0px,0px,0px);
  box-shadow: 0px 0px #F3F3F3;
  box-shadow: 0px 0px #F3F3F3;
  transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out 1s, border 0.3 0.3s;
}


/*
.btn3 {
  display: inline-block;
  margin-top: .5em;
  border-radius: 1rem;
  padding: 10px 20px;
  margin-left: 0px;
  border: 2px solid #4863F2;
  color: #4863F2 !important;
  transform: translate3d(0px,-5px,0px);
  box-shadow: 0px 5px#4863F2;
  transition: box-shadow 0.5s ease-in-out .3s, transform 0.5s ease-in-out 0.3s, padding 0.2s 0s;
}

.btn3:hover {
  display: inline-block;
  transform: translate3d(0px,0px,0px);
  box-shadow: 0px 0px #4863F2;
  transition: box-shadow 0.5s ease-in-out .3s, transform 0.5s ease-in-out 0.3s, padding 0.3s 0s;
}

.btn3:active {
  border: 2px solid  #F3F3F3; 
  padding: 10px 40px 10px 0px;
  transition: padding .3s ease-in-out 0.3s, border .3 ease-in-out 0.3s;
}
*/

/***** 01 - 04 Main Backdrops *****/

.color-swatch h3 {
  transform: rotate3d(0, 0, 0, 180deg);
  transition: transform .25s; 
}

.color-swatch p {
  transform: rotate3d(0, 0, 0, 180deg);
  opacity: 0%;
  transition: opacity .5s, transform .25s; 
}

.color-swatch:hover {
  transform: translate3d(5px,-5px,5px);
  box-shadow: -5px 5px #F3F3F3; /* White Smoke */
  transition: transform .5s, box-shadow .5s;
}

.color-swatch:active {
  transform: rotate3d(0, 1, 0, 180deg);
  box-shadow: 0px 0px;
  transition: transform .5s;
}

.color-swatch:active p, .color-swatch:active h3 {
  transform: rotate3d(0, -1, 0, 180deg);
  opacity: 100%;
  transition: opacity .5s, transform .25s;
}

.color-swatch.swatch-3, .color-swatch.swatch-1 {
  border-color: #04071A; /* Faux-Vampire Black */
}

.body-section {
  margin-top: 2em;
  padding: 2.5em 10% 2em 10%;
  border-radius: 100px 5px;
  text-align: left;
}

.body-section.odd {
  border-radius: 5px 100px;
}

.body-section.definition {
  margin-top: 0em;
  padding: 0em 15% 2em 5%;
}

.body-section section, .body-section aside {
  margin-top: 2em;
}

.body-section img {
  margin-top: 1.5em;
  justify-content: center;
}

.body-section .brands section {
  background-color: khaki !important;
  align-items: center;
}

.body-section.definition2 {
  margin-top: 2em;
  padding: 0em 15% 2em 5%;
}

 .cta-case-study {
  margin-top: 2em;
  padding: 2.5em 10% 2em 10%;
}

.past-work-spacing {
    padding: 2% 0% 0% 0%;
}

.main-section {
  margin-top: 2rem;
  border-radius: 50px 50px;
}

.intro {
  padding: 2.5em 10% 2em 10%;
  text-align: left;
  border-radius: 100px 5px;
}

.intro h1 {
  position: relative; 
  margin-bottom: 0.5em;
}

.underliner {
  letter-spacing: -.15em;
  margin-left: 1.5em;
}

.line-break {
  border-radius: .3rem ;
  margin-left: -15%;
  margin-top: 2em;
  margin-bottom: 2em;
  border: .3rem solid #F3F3F3 ;
  height: 0em;
}

.line-break.flip {
  margin-left: 0%;
  margin-right: -15%;
}

/***** *****/

.h3 {
  font-size: 1.25rem; /* ratio is major 3rd from next lowest */
  font-family: "Quicksand", sans-serif; 
}

.align-left {
  text-align: left;
}

.values {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: last baseline;
}

.values section {
  flex-basis: 300px;
  flex-grow: 2;
  flex-shrink: 2;
  margin: 0% 10% 0% 0%;
}


/*** 02-01: Clients ***/

div.past-clients {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  margin: 0rem 0 1rem 0;
}

img.past-clients-img{
    width: 80%;
    height: 80%;
}

h3.past-clients {
  text-align: center;
}

/***** 02-02: Past Work *****/
.past-work h5 {
  margin: 1.5em 0;
}

/***** 03-01: Disclaimer *****/

#Disclaimer {
  margin-top: .5em;
  margin-left: -.3em;
  margin-bottom: .5em
}

/*****  xx Scrap *****/


/*** 06 Index.html ***/


/***** yy Design Guide Only Code *****/

.color-swatch {
  text-align: left;
  width: 40%;
  max-width: 300px;
  min-width: 200px;
  min-height: 250px;
  display: inline-block;
  padding: 20px 15px;
  margin: 20px 15px;
  border: .5em solid #F3F3F3;
  border-radius: 3px 35px 3px 35px;
  box-shadow: 0px 0px;
  transition: transform .5s, box-shadow .5s;
}

.dg-title {
  text-align: left;
  line-height: .9;
}

.dg-lora{
  font-style: normal;
}

.v-Num {
  font-size: 1rem;
  margin-left: 0;
}

.dg-box-set{
  max-width: 100%;
  align-content: center;
  margin: 0 auto;
  padding: 2rem 5% 4rem 5%;
  overflow: hidden;
}

.musicality-ratio {
  text-align: left;
  padding: 2% 10% 0% 10%;
}

.main-section.design-guide-section {
  padding: 1em 20px 1em 20px;
}

.main-section.disclaimer {
  padding: 0rem 2rem 0.3rem 2rem;
  position: relative;
  left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
  margin-left: auto;
  /* z-index: -1; */
  max-width: 30rem;
}

.design-guide-section2 {
  padding: 1em 2%;
  text-align: center;
}

.about-container {
  display: flex;
}

.word-break {
  word-break: break-word;
}
 
/***** 07 Case Studies *****/

.case-study p {
  margin: 1.5em 0;
}

.case-study section.about {
  margin-right: 15%;
}

.case-study section.challenge {
  margin-left: 15%;
  position: relative;
  margin-top: -5rem;
  padding-top: 6rem;
  z-index: -1;
}

.cta-case-study {
  margin: 2em 2%;
}

.cta-case-study h3 {
  margin-bottom: 1.5em;
}

/***** 08 Media Queries *****/

@media only screen and (min-width: 600px) {
  input {
    max-width: 100%;
  }
  .main-nav-bar {
    flex-wrap: nowrap;
  }

  .main-nav-bar .header-nav {
    flex-wrap: nowrap;
  }

  .main-nav-bar .header-nav li{
    flex-wrap: nowrap;
  }

  .header-nav .nav-item.first{
    padding-top: .5rem;
  }

  .header-nav .nav-item.last {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  div.past-clients {
    justify-content: space-between;
    flex-direction: row;
    justify-items: center;
  }

  img.past-clients-img {
    width: 40%;
    height: 40%;
    margin: 0 auto;
  }

}

@media only screen and (min-width: 960px) {
/*  html {
    font-size: 20px;
  }*/

  form{
    width: 100%;
    font-size: 1.563rem;
  } 
    
  .main-section {
    margin: 2em 0%;
  }

  .main-section.design-guide-section {
    padding: 1em 20px 1em 20px;
  }

  .main-section.disclaimer {
    padding: 2rem 2rem 1rem 2rem;
    margin-top: -2rem;
  }

  .box-set{
    padding: 2rem 10% 4rem 10%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .musicality-ratio {
      text-align: left;
  }

  h1 {
      font-size: 5.492rem; /* ratio is major fifth from next lowest */
  }
    
  h2 {
      font-size: 2.441rem; /* ratio is major 3rd from next lowest */
  }
    
  h3 {
    font-size: 1.563rem; /* ratio is major 3rd from next lowest */
  }
  
  h4 {
    font-size: 1rem; /* base of "chord" */
  }
  
  h5 {
    font-size: 0.75rem; /* ratio is perfect 4th smaller from base */
  }
  
  h6 {
    font-size: 0.6rem; /* ratio is major 3rd smaller from h5 */
  }  

  .h3 {
    font-size: 1.563rem; /* ratio is major 3rd from next lowest */
    font-family: "Quicksand", sans-serif; 
  }

  .h4 {
    font-size: 1rem;
  }

  .v-Num {
    font-size: 1.563rem;
    margin-left: 0;
  }

  form{
    font-size: 1.563rem;
  }

  input {
    max-width: 100%;
  }
  
  footer {
   padding: 3em 10% 1.5em 10%;
  }

  .foot-container {
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .body-section section, .body-section img {
    margin-top: 1em;
  }

  .case-study section.challenge {
    padding-top: 4rem;
  }
/*
  .line-break {
    border-radius: .5rem ;
    border: .5rem solid #F3F3F3 ;
  } */
}
/***** zz Old Code *****/